import { Alert, Box, Divider, Grid, Modal, Paper, Snackbar, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from "react-router-dom";
import { ConInformative } from "../models/AnswerModel";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { conditionTypes } from '../data/ConditionTypes';
import DropdownInput from '../components/forms/DropdownInput';
import ConditionalLineComponent from '../components/Conditions';
import { ConAnswer } from '../models/ConAnswerModel';
import { useEndpoints } from '../utils/EndpointContext';
import { defaultValues } from '../models/defaultValues';
import { Informative } from '../models/InformativeModel';
import { FileModel } from '../models/FileModel';
import { Question } from '../models/QuestionModel';
import { useMsal } from '@azure/msal-react';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
    qId: string;
    iId: string;
    niId: string;
};

const QuestionNestedInformativePage: React.FC = (props: Props) => {
    
    const endPoints = useEndpoints();
    const { accounts } = useMsal();

    let { companyCode, versionId, qId, iId, niId } = useParams<Params>();
    const informativeIndex = (iId) ? parseInt(iId!) : 0;
    const nestedInformativeIndex = (niId) ? parseInt(niId!) : 0;

    const [savedData, setSavedData] = useState<FileModel>();
    const [informatives, setInformatives] = useState<Informative[]>([]);
    const [answer, setAnswer] = useState<ConAnswer>(defaultValues.conAnswer);
    const [informative, setInformative] = useState<ConInformative>(defaultValues.conInformative);
    const [answersIndex, setAnswersIndex] = useState<number>(0);

    const [openAlert, setOpenAlert] = useState<boolean>(false);

    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll'
    };
    
    useEffect(() => {
        axios.get(`${endPoints.questionEditorRead}`,{
            params: {
                partitionKey: 'AW',
                rowKey: versionId,
                downloadData: true
            }
        })
        .then(response => {
            setSavedData(response.data);

            let questionData = response.data.Data!.Questions.find((question: Question) => question.Id == qId);

            if(questionData)
            {
                setInformatives(response.data.Data!.Informatives);

                let answersData = response.data.Data!.Answers.find((element: ConAnswer) => element.QuestionId == qId);

                if(answersData){
                    let answersIndex = response.data.Data!.Answers.findIndex((element: ConAnswer) => element.QuestionId == qId);

                    setAnswersIndex(answersIndex);
                    setAnswer(answersData);
                    setInformative(answersData.Informatives[informativeIndex].NestedInformatives![nestedInformativeIndex]);
                }
                
            }
        });
    }, []);

    const handleSave = async () => {
        try {
            answer.Informatives[informativeIndex].NestedInformatives![nestedInformativeIndex] = informative;
            savedData!.Data!.Answers[answersIndex] = answer;
            savedData!.ModifiedBy = accounts[0]?.name;

            await axios.post(`${endPoints.questionEditorUpdate}`, savedData);
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAlert(true);
        }
    };

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleConditionKeyChange = useCallback((value: string, conditionIndex: number) => {
        const newData = {...informative};
        newData.Conditions[conditionIndex].Key = value;
        setInformative(newData);
    }, [informative]);

    const handleConditionValueChange = useCallback((value: string, conditionIndex: number) => {
        const newData = {...informative};
        newData.Conditions[conditionIndex].Value = value;
        setInformative(newData);
    }, [informative]);

    const handleNestedInformativeAdd = () => {
        const newData = { ...informative };

        if (newData.NestedInformatives == null) { newData.NestedInformatives = []; }

        newData.NestedInformatives = [...newData.NestedInformatives, defaultValues.conInformative];
        setInformative(newData);
    };

    const handleConditionAdd = () => {
        const newData = {...informative};
        newData.Conditions = [...newData.Conditions, defaultValues.condition];
        setInformative(newData);
    }

    const handleConditionRemove = (index: number) => {
        const newData = {...informative};
        newData.Conditions.splice(index, 1);
        setInformative(newData);
    };

    const handleInformativeIdAdd = (informativeId: string) => {
        const newData = {...informative};
        newData.InformativeId = [...newData.InformativeId, informativeId];
        setInformative(newData);
    }

    const handleInformativeIdRemove = (index: number) => {
        const newData = {...informative};
        newData.InformativeId.splice(index, 1);
        setInformative(newData);
    }

    const handleTypeChange = useCallback((value: string) => {
        const newData = {...informative};
        newData.Type = value;
        setInformative(newData);
    }, [informative]);

    const renderNestedInformativeAddButton = () => {
        if (informative.Type !== 'nested') {
            return (<></>);
        }

        return (<Button variant="contained" color='secondary' onClick={handleNestedInformativeAdd}>Add Nested Informative <CreateIcon /></Button>);
    }

    const renderInformativeAddButton = () => {
        if (informative.Type == 'nested') {
            return (<></>);
        }

        return (<Button variant="contained" color='secondary' onClick={handleModalOpen}>Add Informative <CreateIcon /></Button>);
    }

    const handleNestedInformativeRemove = (index: number) => {
        const newData = { ...informative };
        newData.NestedInformatives?.splice(index, 1);
        setInformative(newData);
    };

    const renderInformative = (informativeId: string) => {
        return informatives.map((informative : Informative) => {
            if(informative.Id === informativeId){
                return informative.Content;
            }

            return (<></>);
        });
    }

    const renderInformatives = () => {
        if (informative.Type === 'nested') {
            return (<></>);
        }

        return informatives.map((info : Informative, index : number) => {
            if(!informative.InformativeId.includes(info.Id))
            {
                return (
                    <Stack>
                        <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <Typography><strong>({info.Id})</strong> {info.Content}</Typography>
                                </Grid>
                                <Grid display={'flex'} justifyContent={'flex-end'} item xs={1}>
                                    <Button variant='contained' onClick={() => handleInformativeIdAdd(info.Id)}> <CreateIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                   );
            }
            
            return (<></>);
        });
    }

    return (
        <div>
            {informative && answer ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={6}>

                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                {renderNestedInformativeAddButton()}
                                <Button variant="contained" color='secondary' onClick={handleConditionAdd}>Add Condition <CreateIcon /></Button>
                                {renderInformativeAddButton()}
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Nested Informative <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                {informative.Type != 'nested' ? 
                <Paper>
                    {informative.InformativeId.map((informativeId, informativeIdIndex) => (
                        <Stack key={informativeIdIndex}>
                            <Box sx={{ m: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <Typography><strong>({informativeId})</strong> {renderInformative(informativeId)}</Typography>
                                    </Grid>
                                    <Grid display={'flex'} justifyContent={'flex-end'} item xs={1}>
                                        <Button color='error' onClick={() => handleInformativeIdRemove(informativeIdIndex)}> <DeleteIcon /></Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider variant="middle" />
                        </Stack>
                    ))}
                </Paper> : <></>}
                <Paper style={{marginTop: "1em"}}>
                    <DropdownInput label='Type' fieldName={`type`} value={informative.Type} items={conditionTypes} onChange={(value) => handleTypeChange(value)} />
                    <ConditionalLineComponent data={informative} onHandleConditionKeyChange={handleConditionKeyChange} onHandleConditionValueChange={handleConditionValueChange} onHandleConditionRemove={handleConditionRemove} />
                </Paper>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                {renderNestedInformativeAddButton()}
                                <Button variant="contained" color='secondary' onClick={handleConditionAdd}>Add Condition <CreateIcon /></Button>
                                {renderInformativeAddButton()}
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Nested Informative <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    >
                    <Box sx={style}>
                        {renderInformatives()}
                    </Box>
                </Modal>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Nested informative updated successfully.
                    </Alert>
                </Snackbar>
            </> : <>Loading Nested Informative</>}
        </div>
    );
}

export default QuestionNestedInformativePage;