import { TextField } from '@mui/material';
import React from 'react';

interface TextInputProps {
    fieldName: string;
    label?: string;
    value: string;
    disabled?: boolean;
    multiline?: boolean;
    onChange: (value: string) => void;
}

const TextInput: React.FC<TextInputProps> = React.memo(({ multiline, disabled, label, fieldName, value, onChange }) => {

    return (
        <>
            {multiline ? 
            <TextField multiline rows={2} disabled={disabled} label={label} name={fieldName} value={value} fullWidth onChange={e => onChange(e.target.value)} /> 
            : <TextField disabled={disabled} label={label} name={fieldName} value={value} fullWidth onChange={e => onChange(e.target.value)} /> }
        </>
    );
});

export default TextInput;