import { Alert, Box, Divider, Grid, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Answer, ConInformative } from '../models/AnswerModel';
import ReactHtmlParser from 'react-html-parser'
import { Question } from '../models/QuestionModel';
import { useEndpoints } from '../utils/EndpointContext';
import { defaultValues } from '../models/defaultValues';
import { FileModel } from '../models/FileModel';
import { Informative } from '../models/InformativeModel';
import { ConAnswer } from '../models/ConAnswerModel';
import TextInput from '../components/forms/TextInput';
import { useMsal } from '@azure/msal-react';
import { AnswerSet } from '../models/AnswerSetModel';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
    qId: string;
};

const ConAnswerPage: React.FC = (props: Props) => {
    const { companyCode, versionId, qId } = useParams<Params>();

    const endPoints = useEndpoints();
    const navigate = useNavigate();
    const { accounts } = useMsal();

    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);
    const [savedData, setSavedData] = useState<FileModel>();
    const [question, setQuestion] = useState<Question>(defaultValues.question);
    const [answers, setAnswers] = useState<ConAnswer>(defaultValues.conAnswer);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [answersIndex, setAnswersIndex] = useState<number>(0);
    const [informatives, setInformatives] = useState<Informative[]>([]);
    
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    useEffect(() => {
        axios.get(`${endPoints.questionEditorRead}`,{
            params: {
                partitionKey: 'AW',
                rowKey: versionId,
                downloadData: true
            }
        })
        .then(response => {
            setSavedData(response.data);
            
            let questionData = response.data.Data!.Questions.find((question: Question) => question.Id == qId);

            setInformatives(response.data.Data!.Informatives);

            if(questionData)
            {
                let questionIndex = response.data.Data!.Questions.findIndex((question: Question) => question.Id == qId);
                setQuestionIndex(questionIndex);
                setQuestion(questionData);

                let answersData = response.data.Data!.Answers.find((answer: ConAnswer) => answer.QuestionId == qId);

                if(answersData){
                    let answersIndex = response.data.Data!.Answers.findIndex((element: ConAnswer) => element.QuestionId == qId);
                    setAnswersIndex(answersIndex);
                    setAnswers(answersData);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }
      }, [itemAdded]);

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = async () => {
        try {
            savedData!.Data!.Questions[questionIndex] = question;
            savedData!.Data!.Answers[answersIndex] = answers;
            savedData!.ModifiedBy = accounts[0]?.name;
            await axios.post(`${endPoints.questionEditorUpdate}`, savedData);
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAlert(true);
        }
    };
    
    function handleIdChange(value: string): void {
        setQuestion(prevQuestion => ({ ...prevQuestion, Id: value }));
    }

    function handleSectionChange(value: string): void {
        setQuestion(prevQuestion => ({ ...prevQuestion, Section: value }));
    }

    function handleQuestionChange(value: string): void {
        setQuestion(prevQuestion => ({ ...prevQuestion, Question: value }));
    }

    const handleAnswerSetAdd = () => {
        let newAnswerSet : AnswerSet = defaultValues.answerSet;
        savedData!.Data!.Answers[answersIndex].AnswersSet.push(newAnswerSet);
        setSavedData(savedData);
        handleSave();
    };

    const handleAnswerSetRemove = (index: number) => {
        savedData!.Data!.Answers[answersIndex].AnswersSet.splice(index, 1);
        setSavedData(savedData);
        handleSave();
    };

    const handleInformativeAdd = () => {
        let newInformative : ConInformative = defaultValues.conInformative;
        savedData!.Data!.Answers[answersIndex].Informatives.push(newInformative);
        setSavedData(savedData);
        handleSave();
    };

    const handleInformativeRemove = (index: number) => {
        savedData!.Data!.Answers[answersIndex].Informatives.splice(index, 1);
        setSavedData(savedData);
        handleSave();
    };

    const handleAnswerAdd = (answerSetIndex: number) => {
        let newAnswer : Answer = defaultValues.answer;
        savedData!.Data!.Answers[answersIndex].AnswersSet[answerSetIndex].Answers.push(newAnswer);
        setSavedData(savedData);
        handleSave();
    };

    const handleAnswerRemove = (answerSetIndex: number, index: number) => {
        savedData!.Data!.Answers[answersIndex].AnswersSet[answerSetIndex].Answers.splice(index, 1);
        setSavedData(savedData);
        handleSave();
    };

    const renderConditions = (answer: Answer | ConInformative) => {
        if(answer.Type == "notand"){
            return (<>{answer.Conditions[0].Key} not equal to "{answer.Conditions[0].Value}"</>);
        }else if(answer.Type == "and"){
            return answer.Conditions.map((condition) => {
                return <>{condition.Key} equal to "{condition.Value}" <strong>AND</strong> </>
            });
        }else if(answer.Type == "or"){
            return answer.Conditions.map((condition) => {
                return <>{condition.Key} equal to "{condition.Value}" <strong>OR</strong> </>
            });
        }else if(answer.Type == "nested"){
            return answer.Conditions.map((condition) => {
                return <><strong>NESTED</strong> {condition.Key} equal to "{condition.Value}" <strong>AND</strong> </>
            });
        }

        return (<></>);
    }

    const renderInformative = (informativeId: string) => {
        return informatives.map((informative) => {
            if(informative.Id === informativeId){
                return <><small><strong>{informative.Id}</strong></small> | {informative.Content}</>;
            }

            return (<></>);
        });
    }

    return(
        <div>
            {question ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={6}>
                       
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question<SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                <Typography>Question</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <TextInput fieldName={'id'} label={'ID'} value={question.Id} onChange={(value) => handleIdChange(value)} />
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput fieldName={'section'} label={'Section'} value={question.Section} onChange={(value) => handleSectionChange(value)} />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextInput fieldName={'question'} label={'Question'} value={question.Question} onChange={(value) => handleQuestionChange(value)} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Typography>Answer Sets</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            {answers.AnswersSet.map((answerSet, answerSetIndex) => (
                                <Grid container spacing={2} key={answerSetIndex}>
                                    <Grid item xs={11}>
                                        <Typography>Answer Set {answerSetIndex + 1}</Typography>
                                        {answerSet.Answers.map((answer, answerIndex) => (
                                            <Stack key={answerIndex}>
                                                <Box sx={{ m: 2 }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={10}>
                                                            <Box><strong>Summary:</strong> {answer.Summary}</Box>
                                                            <Box><strong>Full Answer: </strong> {ReactHtmlParser(answer.Answer)}</Box>
                                                            <Box><small><strong>Conditions:</strong> {renderConditions(answer)}</small></Box>
                                                        </Grid>
                                                        <Grid item xs={2} display="flex" justifyContent="flex-end" >
                                                            <Button color='error' onClick={() => handleAnswerRemove(answerSetIndex, answerIndex)}> <DeleteIcon /></Button>
                                                            <Button variant="contained" color='secondary' onClick={() => navigate(`answers/${answerSetIndex}/${answerIndex}`)}><EditIcon /></Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Divider variant="middle" />
                                            </Stack>
                                        ))}
                                        <Stack>
                                            <Box sx={{ m: 2 }} display="flex" justifyContent="flex-end" className="tools">
                                                <Button variant='contained' color='primary' onClick={() => handleAnswerAdd(answerSetIndex)}>Add Answer <CreateIcon /></Button>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={1} className='actions-bar'>
                                        <Button color='error' onClick={() => handleAnswerSetRemove(answerSetIndex)}> <DeleteIcon /></Button>
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" color='secondary'  onClick={() => handleAnswerSetAdd()}>Add New Set <CreateIcon /></Button>
                        </Box>
                    </Paper>
                    <Typography>Informatives</Typography>
                    <Paper>
                        <Box sx={{ m: 2 }}>
                            {answers.Informatives.map((informative, informativeIndex) => (
                                <Stack key={informativeIndex}>
                                    <Box sx={{ m: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10}>
                                                {informative.InformativeId.map((id) => (<Box sx={{ m: 2 }}><Typography>{renderInformative(id)}</Typography></Box>))}
                                                <Box sx={{ m: 2 }}><small><strong>Conditions:</strong> {renderConditions(informative)}</small></Box>
                                            </Grid>
                                            <Grid item xs={2} display="flex" justifyContent="flex-end" >
                                                <Button color='error' onClick={() => handleInformativeRemove(informativeIndex)}><DeleteIcon /></Button>
                                                <Button variant="contained" color='secondary' onClick={() => navigate(`informative/${informativeIndex}`)}><EditIcon /></Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider variant="middle" />
                                </Stack>
                            ))}
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant='contained' color='secondary' onClick={() => handleInformativeAdd()}>Add Informative <CreateIcon /></Button>
                        </Box>
                    </Paper>
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save Question <SaveIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Question updated successfully.
                    </Alert>
                </Snackbar>
            </>
            : <>Loading Question</>}
        </div>
    );
}

export default ConAnswerPage;