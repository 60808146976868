import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

interface ConfirmDeleteModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({ open, onClose, onConfirm }) => {

    const [openModal, setOpenModal] = useState(false);

    const handleConfirm = () => {
        onConfirm();
        onClose();
    }

    return (<Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
    >
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <p id="modal-description">Are you sure you want to delete?</p>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color='error' onClick={handleConfirm}>Confirm</Button>
                <Button variant="contained" color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </DialogActions>
    </Dialog>);
}

export default ConfirmDeleteModal;

