import { Alert, Box, Grid, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Question } from '../models/QuestionModel';
import { useEndpoints } from '../utils/EndpointContext';
import { FileModel } from '../models/FileModel';
import ConfirmDeleteModal from '../components/modals/ConfirmDeleteModal';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
};

const QuestionsPage: React.FC = (props: Props) => {

    const { companyCode, versionId } = useParams<Params>();

    const endPoints = useEndpoints();
    const navigate = useNavigate();

    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);

    const [questions, setQuestions] = useState<Question[]>();

    const [savedData, setSavedData] = useState<FileModel>();
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    useEffect(() => {
        axios.get(`${endPoints.questionEditorRead}`, {
            params: {
                partitionKey: companyCode,
                rowKey: versionId,
                downloadData: true
            }
        }).then(response => {
            setSavedData(response.data);
            setQuestions(response.data.Data!.Questions);
        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }

        if(itemAdded){
            handleSave();
        }
      }, [itemAdded]);

    const handleSave = async () => {
        try {
            savedData!.Data!.Questions = questions!;
            await axios.post(`${endPoints.questionEditorUpdate}`, savedData);
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAlert(true);
        }
    };

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleAdd = () => {

        let nextVersion = '1.0';

        if(questions!.length > 0){
            const lastVersion = questions![questions!.length - 1].Id;
            nextVersion = incrementVersion(lastVersion);
        }

        const newQuestion: Question = {
            Id: nextVersion,
            Section: '',
            Question: ''
        };

        setQuestions(prevQuestions => [...prevQuestions!, newQuestion]);
        setItemAdded(true);
    };

    const handleRemove = (index: number) => {
        setDeleteIndex(index);
        setOpenDeleteModal(true);
    };

    const confirmRemove = () => {
        if (deleteIndex !== null) {
            setQuestions(prevQuestions => prevQuestions!.filter((_, i) => i !== deleteIndex));
            setItemAdded(true);
            setOpenDeleteModal(false);
        }
    };
    
    const incrementVersion = (version: string): string => {
        const parts = version.split('.').map(Number);
        
        if (parts.length === 3) {
            parts[2] += 1;
        } else if (parts.length === 2) {
            parts.push(1);
        }

        if (parts[2] && parts[2] > 9) {
            parts[2] = 0;
            parts[1] += 1;
        }
        
        if (parts[1] && parts[1] > 9) {
            parts[1] = 0;
            parts[0] += 1;
        }
        
        return parts.join('.');
    }

    return(
        <div>
            {questions ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="secondary" onClick={handleAdd}>Create Question <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    {questions!.map((item, index) => (
                        <Paper key={index}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <Typography>{item.Id}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography>{item.Section}</Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography>{item.Question}</Typography>
                                </Grid>
                                <Grid item xs={3} className='actions-bar tools' display="flex" justifyContent="flex-end">
                                    <Button color='primary' onClick={() => { navigate(`${item.Id}`) }}> <EditIcon /></Button>
                                    <Button color='error' onClick={() => handleRemove(index)}> <DeleteIcon /></Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color="secondary" onClick={handleAdd}>Create Question <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Question added successfully.
                    </Alert>
                </Snackbar>
                <ConfirmDeleteModal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} onConfirm={confirmRemove} />
            </>
            : <>Loading Questions</>}
        </div>
    );
}

export default QuestionsPage;