import { Paper, Grid, Button, Stack } from "@mui/material";

import logo from '../geodesysLogoWhite.svg';
import swlogo from '../swlogo.svg';
import { useNavigate } from "react-router-dom";


type Props = {}

const DashboardPage: React.FC = (props: Props) => {

    const navigate = useNavigate();
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Paper style={{marginBottom: '1em'}}>
                    <Stack spacing={2}>
                        <div style={{height: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                            <img src={logo} alt="logo" width={200} />
                        </div>
                        <Button variant="contained" color='primary' onClick={() => navigate(`/AW/dataversions/Commercial`)}>Commercial</Button>
                        <Button variant="contained" color='secondary' onClick={() => navigate(`/AW/dataversions/Residential`)}>Residential</Button>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper style={{marginBottom: '1em'}}>
                    <Stack spacing={2}>
                        <div style={{height: '100px', width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                            <img src={swlogo} alt="logo" width={200} />
                        </div>
                        <Button variant="contained" color='primary' onClick={() => navigate(`/SW/dataversions/Commercial`)}>Commercial</Button>
                        <Button variant="contained" color='secondary' onClick={() => navigate(`/SW/dataversions/Residential`)}>Residential</Button>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default DashboardPage;