import { Box, Typography } from "@mui/material";

type Props = {}

const ErrorPage: React.FC = (props: Props) => {
    return(<Box>
        <Typography>Error</Typography>
        <Typography>Unauthorised</Typography>
    </Box>);
}

export default ErrorPage;