import { Alert, Box, Divider, Grid, Modal, Paper, Snackbar, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CreateIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Placeholder from '../components/rte/Placeholder';
import { getQuestions } from '../data/QuestionAndAnswerService';
import TextInput from '../components/forms/TextInput';
import { useEndpoints } from '../utils/EndpointContext';
import { defaultValues } from '../models/defaultValues';
import { FileModel } from '../models/FileModel';
import { SimpleQuestion } from '../models/SimpleQuestionModel';
import { SimpleAnswer } from '../models/SimpleAnswerModel';
import { Informative } from '../models/InformativeModel';
import { useMsal } from '@azure/msal-react';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
    qId: string;
    aId: string;
};

const SimpleAnswerPage: React.FC = (props: Props) => {
    const { versionId, qId } = useParams<Params>();
    const aId = Number(useParams<Params>().aId ?? -1);

    const endPoints = useEndpoints();
    const { accounts } = useMsal();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [savedData, setSavedData] = useState<FileModel>();
    const [informatives, setInformatives] = useState<Informative[]>([]);
    const [question, setQuestion] = useState<SimpleQuestion>(defaultValues.simpleQuestion);
    const [answer, setAnswer] = useState<SimpleAnswer>(defaultValues.simpleAnswer);
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '80%',
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll'
    };

    useEffect(() => {
        axios.get(`${endPoints.questionEditorRead}`,{
            params: {
                partitionKey: 'SW',
                rowKey: versionId,
                downloadData: true
            }
        })
        .then(response => {
            setSavedData(response.data);
            
            let questionData = response.data.Data!.Questions.find((question: SimpleQuestion) => question.Id == qId);

            if(questionData)
            {
                let questionIndex = response.data.Data!.Questions.findIndex((question: SimpleQuestion) => question.Id == qId);
                setInformatives(response.data.Data!.Informatives);

                setQuestionIndex(questionIndex);
                setQuestion(questionData);

                if(aId > -1){
                    let answerData = questionData.Answers[aId];
                    setAnswer(answerData);
                    setEditorState(EditorState.createWithContent(stateFromHTML(answerData.Answer)));
                }else{
                    let newAnswer = defaultValues.simpleAnswer;
                    newAnswer.Informatives = [];
                    setAnswer(newAnswer);
                }
            }

        });
    }, []);

    const handleEditorChange = (state: EditorState) => {
        setEditorState(state);
        handleAnswerChange(stateToHTML(state.getCurrentContent()));
    }

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = async () => {
        try {
            if(question.Answers === undefined){
                question.Answers = [];
            }

            if(aId > -1){
                question.Answers[aId] = answer;
            }else{
                question.Answers.push(answer);
            }

            savedData!.Data!.Questions[questionIndex] = question;
            savedData!.ModifiedBy = accounts[0]?.name;
            await axios.post(`${endPoints.questionEditorUpdate}`, savedData);
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAlert(true);
        }
    };

    const handleAnswerChange = useCallback((value: string) => {
        setAnswer(prevAnswer => ({ ...prevAnswer, Answer: value }));
    }, []);

    const handleConditionChange = useCallback((value: string) => {
        setAnswer(prevAnswer => ({ ...prevAnswer, Condition: value }));
    }, []);

    const handleSummaryChange = useCallback((value: string) => {
        setAnswer(prevAnswer => ({ ...prevAnswer, Summary: value }));
    }, []);

    const handleInformativeIdAdd = (id: string) => {
        setAnswer(prevAnswer => {
            const newInformatives = [...(prevAnswer.Informatives || []), id];
            return { ...prevAnswer, Informatives: newInformatives };
        });
    }

    const handleInformativeIdRemove = (index: number) => {
        setAnswer(prevAnswer => {
            const newInformatives = [...(prevAnswer.Informatives || [])];
            newInformatives.splice(index, 1);
            return { ...prevAnswer, Informatives: newInformatives };
        });
    }

    const renderInformatives = () => {
        return informatives.filter(informative => !answer.Informatives?.includes(informative.Id)).map((informative, index) => (
                    <Stack>
                        <Box sx={{ m: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <Typography><strong>({informative.Id})</strong> {informative.Content}</Typography>
                                </Grid>
                                <Grid display={'flex'} justifyContent={'flex-end'} item xs={1}>
                                    <Button variant='contained' onClick={() => handleInformativeIdAdd(informative.Id)}> <CreateIcon /></Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
        ));
    }

    const renderInformative = (informativeId: string) => {
        return informatives.map((informative) => {
            if(informative.Id === informativeId){
                return informative.Content;
            }

            return (<></>);
        });
    }

    const renderAnswer = () => {
        return (<Paper>
            <Typography sx={{ mb: 2 }}>Full Answer</Typography>
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'remove', 'history'],
                }}
                toolbarCustomButtons={[
                    <Placeholder
                        editorState={editorState}
                        onChange={handleEditorChange}
                        options={[...getQuestions(), 'waterSupplierAddress', 'waterSupplierCompanyName', 'sewerageSupplierAddress', 'sewerageSupplierCompanyName']} />]}
            />
        </Paper>);
    }

    return (
        <div>
            {question && answer ?
                <>
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginBottom: '1em' }}>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" className="tools">
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Stack spacing={2}>
                        <Typography>Question</Typography>
                        <Paper>
                            {question.Question}
                        </Paper>
                        <Typography>Answer</Typography>
                        <Paper>
                            <Stack>
                                <Box sx={{ m: 2 }}>
                                    <TextInput label='Condition' fieldName={`Condition`} value={answer.Condition} onChange={(value) => handleConditionChange(value)} />
                                </Box>
                                <Box sx={{ m: 2 }}>
                                    <TextInput label='Summary Answer' fieldName={`Summary`} value={answer.Summary} onChange={(value) => handleSummaryChange(value)} />
                                </Box>
                                <Box sx={{ m: 2 }}>
                                    {renderAnswer()}
                                </Box>
                            </Stack>
                        </Paper>
                        <Typography>Informatives</Typography>
                        <Paper>
                            <Box sx={{ m: 2 }}>
                                {answer.Informatives?.map((Id, index) => (
                                    <Stack key={index}>
                                        <Box sx={{ m: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={11}>
                                                    <Typography><strong>({Id})</strong> {renderInformative(Id)}</Typography>
                                                </Grid>
                                                <Grid display={'flex'} justifyContent={'flex-end'} item xs={1}>
                                                    <Button color='error' onClick={() => handleInformativeIdRemove(index)}> <DeleteIcon /></Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider variant="middle" />
                                    </Stack>
                                ))}
                                <Box display="flex" justifyContent="flex-end" className="tools" style={{ marginTop: '1em' }}>
                                    <Button variant="contained" color='secondary' onClick={handleModalOpen}>Add Informative <CreateIcon /></Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Stack>
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: '1em' }}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end" className="tools">
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" color='primary' onClick={handleSave}>Save Answer <SaveIcon /></Button>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Answer updated successfully.
                        </Alert>
                    </Snackbar>
                    <Modal
                        open={modalOpen}
                        onClose={handleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        >
                        <Box sx={modalStyle}>
                            {renderInformatives()}
                        </Box>
                    </Modal>
                </> : <>Loading Answer</>}
        </div>
    );
}

export default SimpleAnswerPage;