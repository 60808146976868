import { Alert, Grid, Paper, Snackbar, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useEndpoints } from '../utils/EndpointContext';
import TextInput from '../components/forms/TextInput';
import { FileModel } from '../models/FileModel';
import Moment from 'react-moment';
import { useMsal } from '@azure/msal-react';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
};

const DataPage: React.FC = (props: Props) => {

    const { companyCode, versionId } = useParams<Params>();

    const endPoints = useEndpoints();
    const { accounts } = useMsal();

    const [savedData, setSavedData] = useState<FileModel>();
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`${endPoints.questionEditorRead}`, {
            params: {
                partitionKey: companyCode,
                rowKey: versionId
            }
        }).then(response => {
            setSavedData(response.data);
        });
    }, [companyCode, versionId]);

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    const handleSave = () => {

        let item = savedData;
        item!.ModifiedBy = accounts[0]?.name;
        axios.post(`${endPoints.questionEditorUpdate}`, item).then(() => {
            setOpenAlert(true);
        });
    };

    const handleUpdateNote = async (note: string) => {
        if (savedData) {
            const updatedData = { ...savedData, Notes: note };
            setSavedData(updatedData);
        }
    };

    return(
        <div>
            <Paper style={{marginBottom: '1em'}}>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={4}>
                            <Grid container spacing={2} marginBottom={'1em'}>
                                <Grid item xs={3} alignContent={'center'} alignSelf={'center'}><Typography fontWeight={600} align={'right'} marginRight={'1em'}>Id</Typography></Grid>
                                <Grid item xs={9} alignContent={'center'} alignSelf={'center'}>{savedData?.RowKey}</Grid>
                            </Grid>
                            <Grid container spacing={2} marginBottom={'1em'}>
                                <Grid item xs={3} alignContent={'center'} alignSelf={'center'}><Typography fontWeight={600} align={'right'} marginRight={'1em'}>Product</Typography></Grid>
                                <Grid item xs={9} alignContent={'center'} alignSelf={'center'}>{savedData?.PartitionKey} {savedData?.ProductType}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container spacing={2} marginBottom={'1em'}>
                                <Grid item xs={4} alignContent={'center'} alignSelf={'center'}><Typography fontWeight={600} align={'right'} marginRight={'1em'}>Date Created</Typography></Grid>
                                <Grid item xs={8} alignContent={'center'} alignSelf={'center'}><Moment format="DD/MM/YYYY HH:mm">{savedData?.DateCreated}</Moment></Grid>
                            </Grid>
                            <Grid container spacing={2} marginBottom={'1em'}>
                                <Grid item xs={4} alignContent={'center'} alignSelf={'center'}><Typography fontWeight={600} align={'right'} marginRight={'1em'}>Created By</Typography></Grid>
                                <Grid item xs={8} alignContent={'center'} alignSelf={'center'}>{savedData?.CreatedBy}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Grid container spacing={2} marginBottom={'1em'}>
                                <Grid item xs={4} alignContent={'center'} alignSelf={'center'}><Typography fontWeight={600} align={'right'} marginRight={'1em'}>Date Modified</Typography></Grid>
                                <Grid item xs={8} alignContent={'center'} alignSelf={'center'}><Moment format="DD/MM/YYYY HH:mm">{savedData?.DateModified!}</Moment></Grid>
                            </Grid>
                            <Grid container spacing={2} marginBottom={'1em'}>
                                <Grid item xs={4} alignContent={'center'} alignSelf={'center'}><Typography fontWeight={600} align={'right'} marginRight={'1em'}>Modified By</Typography></Grid>
                                <Grid item xs={8} alignContent={'center'} alignSelf={'center'}>{savedData?.ModifiedBy}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Stack spacing={2}>
                                <Button variant="contained" color="secondary" component={Link} to={'companies'}>Edit Companies</Button>
                                <Button variant="contained" color="secondary" component={Link} to={'informatives'}>Edit Informatives</Button>
                                <Button variant="contained" color="secondary" component={Link} to={'questions'}>Edit Questions and Answers</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={1} alignContent={'center'} alignSelf={'center'}><Typography fontWeight={600} align={'right'} marginRight={'1em'}>Notes</Typography></Grid>
                        <Grid item xs={11} alignContent={'center'} alignSelf={'center'}><TextInput value={savedData?.Notes! || ''} fieldName={'notes'} onChange={value => handleUpdateNote(value)} /></Grid>
                    </Grid>
                    <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                        <Button variant="contained" color="primary" onClick={handleSave}>Save</Button>
                    </Stack>
                </Stack>
            </Paper>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Question updated successfully.
                </Alert>
            </Snackbar>
        </div>
    );
}

export default DataPage;