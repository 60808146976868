import { Alert, Box, Grid, Paper, Snackbar, Stack } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { Company } from '../models/CompanyModel';
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import TextInput from '../components/forms/TextInput';
import { useEndpoints } from '../utils/EndpointContext';
import { useParams } from 'react-router-dom';
import { FileModel } from '../models/FileModel';
import { defaultValues } from '../models/defaultValues';

type Props = {}
type Params = {
    companyCode: string;
    productType: string;
    versionId: string;
};

const CompaniesPage: React.FC = (props: Props) => {

    const { companyCode, productType, versionId } = useParams<Params>();

    const endPoints = useEndpoints();
    const itemsEndRef = useRef<null | HTMLDivElement>(null);
    const [itemAdded, setItemAdded] = useState(false);
    const [savedData, setSavedData] = useState<FileModel>();
    const [companies, setCompanies] = useState<Company[]>();
    const [openAlert, setOpenAlert] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`${endPoints.questionEditorRead}`,{
            params: {
                partitionKey: companyCode,
                rowKey: versionId,
                downloadData: true
            }
        })
        .then(response => {
            setSavedData(response.data);
            setCompanies(response.data.Data!.Companies);
        });
    }, []);

    useEffect(() => {
        if (itemAdded && itemsEndRef.current) {
          itemsEndRef.current.scrollIntoView({ behavior: 'smooth' });
          setItemAdded(false);
        }
    }, [itemAdded]);

    const handleUpdateKey = (value: string, index: number) => {
        setCompanies(prevData => 
            prevData!.map((item, i) => 
                i === index ? { ...item, Id: value } : item
            )
        );
    };
    
    const handleUpdateName = (value: string, index: number) => {
        setCompanies(prevData => 
            prevData!.map((item, i) => 
                i === index ? { ...item, Name: value } : item
            )
        );
    };
    
    const handleUpdateAddress = (value: string, index: number) => {
        setCompanies(prevData => 
            prevData!.map((item, i) => 
                i === index ? { ...item, Address: value } : item
            )
        );
    };

    const handleCreate = () => {
        setCompanies(prevData => [...prevData!, { ...defaultValues.company }]);
        setItemAdded(true);
    };

    const handleDelete = (index: number) => {
        setCompanies(prevData => prevData!.filter((_, i) => i !== index));
    };

    const handleSave = async () => {
        try {
            savedData!.Data!.Companies = companies!;
            await axios.post(`${endPoints.questionEditorUpdate}`, savedData);
        } catch (error) {
            console.log(error);
        } finally {
            setOpenAlert(true);
        }
    };

    const handleAlertClose = () => {
        setOpenAlert(false);
    }

    return(
        <div>
            {companies ?
            <>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save <SaveIcon /></Button> 
                                <Button variant="contained" color="secondary" onClick={handleCreate}>Create <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    {companies.map((item, index) => (
                        <Paper key={index}>
                            <Stack>
                                <Grid container spacing={2} sx={{mb: "1em"}}>
                                    <Grid item xs={3}>
                                        <TextInput label={'Key'} fieldName={'key'} value={item.Id || ''} onChange={(value) => handleUpdateKey(value, index)} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextInput label={'Name'} fieldName={'Name'} value={item.Name || ''} onChange={(value) => handleUpdateName(value, index)} />
                                    </Grid>
                                    <Grid item xs={1} className='actions-bar'>
                                        <Button color='error' onClick={() => handleDelete(index)}> <DeleteIcon /></Button>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <TextInput label={'Address'} multiline={true} fieldName={'address'} value={item.Address} onChange={(value) => handleUpdateAddress(value, index)} />
                        </Paper>
                    ))}
                </Stack>
                <div ref={itemsEndRef}></div>
                <Grid container spacing={2} justifyContent="flex-end" style={{marginTop: '1em'}}>
                    <Grid item xs={10}></Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" className="tools">
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" color='primary' onClick={handleSave}>Save <SaveIcon /></Button> 
                                <Button variant="contained" color="secondary" onClick={handleCreate}>Create <CreateIcon /></Button>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleAlertClose}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        Companies updated successfully.
                    </Alert>
                </Snackbar>
            </>
            : <>Loading Companies</>}
        </div>
    );
}

export default CompaniesPage;